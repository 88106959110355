import { inject, Injectable } from "@angular/core";
import axios from "axios/dist/axios";
import { LaunchFlagsService } from "src/app/shared/services/launch-flags/launch-flags.service";
import { WebhooksApiService } from "src/app/webhooks/services/webhooks-api.service";
import type { WebhookID } from "src/app/webhooks/services/webhooks.model";

enum WebhookFlags {
   // Originally this was just for list endpoints, but it's probably simpler long-term to just have
   // all the endpoints use the same flag.
   UseLumberyard = "release-lumberyard-for-webhooks-list",
}

interface LegacyWebhook {
   webhookID: number;
   customer_url: string;
   webhook_category: string;
   customerID: number;
   isEnabled: boolean;
   createdAt: number;
   updatedAt: number;
}

interface LegacyWebhookResponse {
   data: {
      webhooks: Record<string, Array<LegacyWebhook>>;
      success: boolean;
   };
}

@Injectable({ providedIn: "root" })
export class ManageWebhooks {
   private readonly axios = axios;
   private readonly launchFlagsService = inject(LaunchFlagsService);
   private readonly apiService = inject(WebhooksApiService);

   public async getWebhooks(): Promise<LegacyWebhookResponse> {
      if (await this.useLumberyard()) {
         const data = await this.apiService.listWebhooks();
         const result: Record<string, Array<LegacyWebhook>> = {};
         for (const webhook of data) {
            if (!result[webhook.category]) {
               result[webhook.category] = [];
            }
            result[webhook.category].push({
               webhookID: webhook.id,
               customer_url: webhook.customerURL,
               webhook_category: webhook.category,
               customerID: webhook.customerID,
               isEnabled: webhook.isEnabled,
               createdAt: webhook.createdAt.getTime() / 1000,
               updatedAt: webhook.updatedAt.getTime() / 1000,
            });
         }
         return {
            data: {
               webhooks: result,
               success: true,
            },
         };
      }
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageWebhooks.php",
         params: {
            action: "getWebhooks",
         },
         data: {},
      });
      return post;
   }

   public async setWebhookURL(
      type: string,
      url: string,
      webhookID: WebhookID,
   ): Promise<{
      data: {
         success: boolean;
         webhookID: number;
      };
   }> {
      if (await this.useLumberyard()) {
         if (webhookID) {
            await this.apiService.updateWebhook(webhookID, { url });
            return {
               data: {
                  success: true,
                  webhookID,
               },
            };
         }
         const response = await this.apiService.createWebhook({ category: type, url });
         return {
            data: {
               success: true,
               webhookID: response.webhookID,
            },
         };
      }
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageWebhooks.php",
         params: {
            action: "setWebhookURL",
         },
         data: {
            type: type,
            url: url,
            webhookID: webhookID,
         },
      });
      return post;
   }

   public async removeWebhook(webhookID: WebhookID): Promise<{
      data: {
         success: boolean;
      };
   }> {
      if (await this.useLumberyard()) {
         await this.apiService.deleteWebhook(webhookID);
         return {
            data: {
               success: true,
            },
         };
      }
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageWebhooks.php",
         params: {
            action: "removeWebhook",
         },
         data: {
            webhookID: webhookID,
         },
      });
      return post;
   }

   generateAPIKeys = async (apiCredType: string) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageWebhooks.php",
         params: {
            action: "generateAPIKeys",
         },
         data: {
            type: apiCredType,
         },
      });
      return post;
   };

   getAPIKeys = async () => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageWebhooks.php",
         params: {
            action: "getAPIKeys",
         },
         data: {},
      });
      return post;
   };

   getEncodedAPICredByType = async (apiCredType: string) => {
      const post = this.axios({
         method: "POST",
         url: "phpscripts/manageWebhooks.php",
         params: {
            action: "getEncodedAPICredByType",
         },
         data: {
            type: apiCredType,
         },
      });
      return post;
   };

   private async useLumberyard(): Promise<boolean> {
      return this.launchFlagsService.getFlagPromise(WebhookFlags.UseLumberyard, false);
   }
}
