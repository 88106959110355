import { Injectable } from "@angular/core";
import axios from "axios/dist/axios";
import type { EnvRoutes } from "src/app/shared/services/envRoutes";

@Injectable({ providedIn: "root" })
export class EnvRoutesService {
   private envRoutes?: EnvRoutes;
   private readonly promise;

   public constructor() {
      this.promise = axios.get("/routes.json").then((response: any) => {
         this.envRoutes = response.data;
      });
   }

   public async getRoutes(): Promise<EnvRoutes> {
      if (!this.envRoutes) {
         await this.promise;
      }
      if (!this.envRoutes) {
         throw new Error("Routes not loaded");
      }
      return this.envRoutes;
   }
}
