import { inject, Injectable } from "@angular/core";
import axios from "axios/dist/axios";
import { EnvRoutesService } from "src/app/shared/services/envRoutes.service";

@Injectable({
   providedIn: "root",
})
export class ManageAtlas {
   private readonly axios = axios;
   private readonly envRoutesService = inject(EnvRoutesService);

   public async getCurrentAtlasUser(encodedArcGisAPICred) {
      const routes = await this.envRoutesService.getRoutes();
      const get = await this.axios.get(`${routes.atlasUrl}/atlas-users/current`, {
         headers: { Authorization: `Basic ${encodedArcGisAPICred}` },
      });
      return get;
   }

   public async getArcgisUser(encodedArcGisAPICred) {
      const routes = await this.envRoutesService.getRoutes();
      const get = await this.axios.get(`${routes.atlasUrl}/oauth/user`, {
         headers: { Authorization: `Basic ${encodedArcGisAPICred}` },
      });
      return get;
   }

   public async authorizeArcgisUser(encodedArcGisAPICred, authCode) {
      const routes = await this.envRoutesService.getRoutes();
      const post = await this.axios.post(
         `${routes.atlasUrl}/oauth/authorize`,
         { authorizationCode: authCode },
         { headers: { Authorization: `Basic ${encodedArcGisAPICred}` } },
      );
      return post;
   }

   public async revokeTokens(encodedArcGisAPICred) {
      const routes = await this.envRoutesService.getRoutes();
      const deleteUser = await this.axios.delete(
         `${routes.atlasUrl}/oauth/revoke-tokens`,
         {
            headers: { Authorization: `Basic ${encodedArcGisAPICred}` },
         },
      );
      return deleteUser;
   }

   public async createAtlasCustomer(encodedArcGisAPICred) {
      const routes = await this.envRoutesService.getRoutes();
      const post = await this.axios({
         method: "post",
         url: `${routes.atlasUrl}/atlas-users`,
         headers: { Authorization: `Basic ${encodedArcGisAPICred}` },
      });
      return post;
   }

   public async updateAtlasCustomer(encodedArcGisAPICred, customerActive) {
      const routes = await this.envRoutesService.getRoutes();
      const patch = await this.axios.patch(
         `${routes.atlasUrl}/atlas-users`,
         { customerActive },
         { headers: { Authorization: `Basic ${encodedArcGisAPICred}` } },
      );
      return patch;
   }

   public async getGisFeatureServices(encodedArcGisAPICred) {
      const routes = await this.envRoutesService.getRoutes();
      const get = await this.axios.get(`${routes.atlasUrl}/feature-services/gis`, {
         headers: { Authorization: `Basic ${encodedArcGisAPICred}` },
      });
      return get;
   }
   public async getFeatureServices(encodedArcGisAPICred) {
      const routes = await this.envRoutesService.getRoutes();
      const get = await this.axios.get(`${routes.atlasUrl}/feature-services`, {
         headers: { Authorization: `Basic ${encodedArcGisAPICred}` },
      });
      return get;
   }
   public async createFeatureService(encodedArcGisAPICred, featureService) {
      const routes = await this.envRoutesService.getRoutes();
      const post = await this.axios.post(
         `${routes.atlasUrl}/feature-services`,
         { featureName: featureService.name, featureUrl: featureService.url },
         { headers: { Authorization: `Basic ${encodedArcGisAPICred}` } },
      );
      return post;
   }
   public async createFeatureWebhook(encodedArcGisAPICred, featureID) {
      const routes = await this.envRoutesService.getRoutes();
      const post = await this.axios({
         method: "post",
         url: `${routes.atlasUrl}/feature-services/${featureID}`,
         headers: { Authorization: `Basic ${encodedArcGisAPICred}` },
      });
      return post;
   }
   public async getGisWebhook(encodedArcGisAPICred, featureID) {
      const routes = await this.envRoutesService.getRoutes();
      const get = await this.axios.get(
         `${routes.atlasUrl}/feature-services/${featureID}/gis-webhooks`,
         {
            headers: { Authorization: `Basic ${encodedArcGisAPICred}` },
         },
      );
      return get;
   }
   public async getGisFeatureLayerFields(encodedArcGisAPICred, featureID) {
      const routes = await this.envRoutesService.getRoutes();
      const get = await this.axios.get(`${routes.atlasUrl}/feature-layers/fields/gis`, {
         headers: { Authorization: `Basic ${encodedArcGisAPICred}` },
         params: { featureID },
      });
      return get;
   }
   public async getFeatureLayerFields(encodedArcGisAPICred, featureID) {
      const routes = await this.envRoutesService.getRoutes();
      const get = await this.axios.get(`${routes.atlasUrl}/feature-layers/fields`, {
         headers: { Authorization: `Basic ${encodedArcGisAPICred}` },
         params: { featureID },
      });
      return get;
   }
   public async createAssetFeatureField(
      encodedArcGisAPICred,
      featureID,
      useAsAssetName,
      field,
   ) {
      const routes = await this.envRoutesService.getRoutes();
      const post = await this.axios.post(
         `${routes.atlasUrl}/feature-layers/fields`,
         { featureID, useAsAssetName, field },
         { headers: { Authorization: `Basic ${encodedArcGisAPICred}` } },
      );
      return post;
   }
   public async updateAssetFeatureField(
      encodedArcGisAPICred,
      featureID,
      useAsAssetName,
      field,
   ) {
      const routes = await this.envRoutesService.getRoutes();
      const patch = await this.axios.patch(
         `${routes.atlasUrl}/feature-layers/fields`,
         { featureID, useAsAssetName, field },
         { headers: { Authorization: `Basic ${encodedArcGisAPICred}` } },
      );
      return patch;
   }

   public async getLimbleLocations(encodedArcGisAPICred) {
      const routes = await this.envRoutesService.getRoutes();
      const get = await this.axios.get(
         `${routes.atlasUrl}/feature-services/limble-locations`,
         { headers: { Authorization: `Basic ${encodedArcGisAPICred}` } },
      );
      return get;
   }
   public async updateFeatureService(encodedArcGisAPICred, featureID, limbleLocationID) {
      const routes = await this.envRoutesService.getRoutes();
      const patch = await this.axios.patch(
         `${routes.atlasUrl}/feature-services/${featureID}`,
         { limbleLocationID },
         { headers: { Authorization: `Basic ${encodedArcGisAPICred}` } },
      );
      return patch;
   }
   public async getGisLayerFeatures(encodedArcGisAPICred, featureID) {
      const routes = await this.envRoutesService.getRoutes();
      const get = await this.axios.get(
         `${routes.atlasUrl}/feature-layers/query/${featureID}`,
         { headers: { Authorization: `Basic ${encodedArcGisAPICred}` } },
      );
      return get;
   }
   public async verifyGisData(encodedArcGisAPICred, gisAssetMaps, featureID) {
      const routes = await this.envRoutesService.getRoutes();
      const post = await this.axios.post(
         `${routes.atlasUrl}/feature-layers/verify-features/${featureID}`,
         { gisData: gisAssetMaps },
         { headers: { Authorization: `Basic ${encodedArcGisAPICred}` } },
      );
      return post;
   }
   public async batchAssetMaps(encodedArcGisAPICred, gisAssetMaps, featureID) {
      const routes = await this.envRoutesService.getRoutes();
      const post = await this.axios.post(
         `${routes.atlasUrl}/mapping/batch`,
         { assetMaps: gisAssetMaps, featureID },
         { headers: { Authorization: `Basic ${encodedArcGisAPICred}` } },
      );
      return post;
   }
   public async getAssetMap(encodedArcGisAPICred, assetID, featureID) {
      const routes = await this.envRoutesService.getRoutes();
      const get = await this.axios.get(`${routes.atlasUrl}/mapping/`, {
         headers: { Authorization: `Basic ${encodedArcGisAPICred}` },
         params: { assetID, featureID },
      });
      return get;
   }
   public async deleteAssetMap(encodedArcGisAPICred, assetID, featureID) {
      const routes = await this.envRoutesService.getRoutes();
      const remove = await this.axios.delete(`${routes.atlasUrl}/mapping/`, {
         headers: { Authorization: `Basic ${encodedArcGisAPICred}` },
         params: { assetID, featureID },
      });
      return remove;
   }

   public async createAssetMap(encodedArcGisAPICred, assetID, featureID, gisGlobalID) {
      const routes = await this.envRoutesService.getRoutes();
      const post = await this.axios.post(
         `${routes.atlasUrl}/mapping/`,
         { assetID, featureID, gisGlobalID },
         { headers: { Authorization: `Basic ${encodedArcGisAPICred}` } },
      );
      return post;
   }

   public async deleteFeatureService(encodedArcGisAPICred, featureID) {
      const routes = await this.envRoutesService.getRoutes();
      const remove = await this.axios.delete(
         `${routes.atlasUrl}/feature-services/${featureID}`,
         {
            headers: { Authorization: `Basic ${encodedArcGisAPICred}` },
         },
      );
      return remove;
   }
}
