import { Injectable } from "@angular/core";
import type { WebhookEntity, WebhookID } from "src/app/webhooks/services/webhooks.model";
import { environment } from "src/environments/environment";

interface RawWebhook {
   id: number;
   customerURL: string;
   category: string;
   customerID: number;
   isEnabled: boolean;
   createdAt: string;
   updatedAt: string;
}

interface ListWebhooksResponse {
   webhooks: Array<RawWebhook>;
}

interface CreateWebhookResponse {
   webhookID: WebhookID;
}

@Injectable({ providedIn: "root" })
export class WebhooksApiService {
   public async listWebhooks(): Promise<Array<WebhookEntity>> {
      const response = await fetch(`${environment.servicesURL()}/webhooks`, {
         method: "GET",
         credentials: "include",
      });
      const data = (await response.json()) as ListWebhooksResponse;

      return data.webhooks.map((raw) => ({
         ...raw,
         createdAt: new Date(raw.createdAt),
         updatedAt: new Date(raw.updatedAt),
      }));
   }

   public async deleteWebhook(id: WebhookID): Promise<void> {
      await fetch(`${environment.servicesURL()}/webhooks/${id}`, {
         method: "DELETE",
         credentials: "include",
      });
   }

   public async createWebhook(params: {
      category: string;
      url: string;
   }): Promise<CreateWebhookResponse> {
      const response = await fetch(`${environment.servicesURL()}/webhooks`, {
         method: "POST",
         credentials: "include",
         body: JSON.stringify(params),
      });
      return (await response.json()) as CreateWebhookResponse;
   }

   public async updateWebhook(
      id: WebhookID,
      params: {
         url: string;
      },
   ): Promise<void> {
      await fetch(`${environment.servicesURL()}/webhooks/${id}`, {
         method: "PATCH",
         credentials: "include",
         body: JSON.stringify(params),
      });
   }
}
